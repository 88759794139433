<template>

  <el-form
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">

          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Service charges</label>
            <el-form-item prop="service_charges" :rules="rules['field']">
              <el-input v-model="objData.service_charges" placeholder="Service charges"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Cancellation fees paid by user if cancelled order after confirmed it" placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Cancellation Fees</label>
            </el-tooltip>
            <el-form-item prop="cancellation_fees" :rules="rules['field']">
              <el-input v-model="objData.cancellation_fees" placeholder="Cancellation Fees"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Will cancel bidding order after created if no provider accepted it" placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Auto Cancel Bidding Order After (Minutes)</label>
            </el-tooltip>
            <el-form-item prop="auto_cancel_bidding_order" :rules="rules['field']">
              <el-input v-model="objData.auto_cancel_bidding_order" placeholder="Auto Cancellation Order After(Minutes)"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Will cancel order after provider accepted it if user not confirmed provider" placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Auto Cancel After Provider Accepted (Minutes)</label>
            </el-tooltip>
            <el-form-item prop="auto_cancel_bidding_order" :rules="rules['field']">
              <el-input v-model="objData.auto_cancel_order_after_accept_provider" placeholder="Auto Cancel Order After Provider Accepted (Minutes)"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Will cancel schedule order if it has not confirmed " placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Auto Cancel Schedule Order After(Minutes)</label>
            </el-tooltip>
            <el-form-item prop="auto_cancel_schedule_order_if_it_has_not_confirmed" :rules="rules['field']">
              <el-input v-model="objData.auto_cancel_schedule_order_if_it_has_not_confirmed" placeholder="Auto Cancel Schedule Order(Minutes)"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Will prevent user from create order if he has reached this limit" placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Wallet User Limit</label>
            </el-tooltip>
            <el-form-item prop="wallet_user_limit" :rules="rules['field']">
              <el-input v-model="objData.wallet_user_limit" placeholder="Wallet User Limit"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <el-tooltip content="Will not send order to provider if he has reached this limit" placement="bottom" effect="light">
              <label class="required fs-6 fw-bold mb-2">Wallet Provider Limit</label>
            </el-tooltip>
            <el-form-item prop="wallet_provider_limit" :rules="rules['field']">
              <el-input v-model="objData.wallet_provider_limit" placeholder="Wallet Provider Limit"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs,watch,onMounted} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {Help, Smoking} from "@element-plus/icons-vue";

export default defineComponent({
  name: "contact-us-comp",
  components: {Help, Smoking, ImageComp},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref([]);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Order Setting", ['Settings']);
    });

    objData.value = {
      service_charges: '',
      cancellation_fees: '',
      auto_cancel_bidding_order : '',
      auto_cancel_order_after_accept_provider : '',
      auto_cancel_schedule_order_if_it_has_not_confirmed : '',
      wallet_user_limit : '',
      wallet_provider_limit : ''
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      try {
        response = await ApiAxiosService.get(APIs.SETTING.order_setting);
        objData.value = response.data.data;
      } catch (e) {
        handleError(e)
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          updateResource();

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.SETTING.order_setting , formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      updateResource,
      type,
      isIndeterminate,
      languages
    };
  },

});
</script>

